






































































import Vue from 'vue'

import NavBarComponent from '@/admin/components/NavBarComponent.vue'
import { AdminLocation } from '@/admin/types'
import { createLocation, getEvent, getLocation, getLocations, updateLocation } from '@/admin/firebase/api'
import { Routes } from '@/admin/config'
import { getLivestreams } from '@/admin/firebase/api'

interface Data {
    loading: boolean
    saving: boolean
    valid: boolean

    id: string
    player_id: string
    livestreams: Array<{ text: string, value: string }>
}

export default Vue.extend({
    name: 'adminLocationEdit',
    data: (): Data => ({
        loading: true,
        saving: false,

        valid: true,

        id: '',
        player_id: '',
        livestreams: [],
    }),
    components: {
        NavBarComponent,
    },
    methods: {
        async loadLocation(id) {

            // livestream names
            const livestreams = await getLivestreams()

            this.livestreams = livestreams.map(l => {
                return {
                    text: `${l.name} - ${l.id}`,
                    value: l.id
                }
            })

            try {
                this.id = id
                const location = await getLocation(id)

                this.id = location.id
                this.player_id = location.player_id

            } catch (e) {
                // pass
            }

            this.loading = false
        },
        async submit() {
            const isValid = (this.$refs.form as any).validate()

            if (isValid) {
                this.saving = true

                console.log('submit data')

                let location: AdminLocation = {
                    id: this.id,
                    player_id: this.player_id,
                }

                await updateLocation(location)

                this.saving = false

                this.$router.push({name: Routes.Locations})
            }

        },
    },
    beforeRouteEnter(to, from, next) {
        console.log('enter')
        console.log(to.params.id)

        next((vm: any) => {
            vm.loadLocation(to.params.id)
        })
    },
    beforeRouteUpdate(to, from, next) {
        console.log('update')
        console.log(to.params.id)

        next((vm: any) => {
            vm.loadLocation(to.params.id)
        })
    },
})

