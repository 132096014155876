















































































































































import Vue from 'vue'

import NavBarComponent from '@/admin/components/NavBarComponent.vue'
import CopyButtonComponent from '@/admin/components/CopyButtonComponent.vue'

import {
  AdminAttendee,
} from '@/admin/types'
import { BASE_URL } from '@/config'

import { Routes } from '@/admin/config'
import { getAttendee } from '@/admin/firebase/api'

interface Data {
    attendee: AdminAttendee | null
    eventId: string | null

    loading: boolean

    BASE_URL: string
    ROUTES: {
        EventView: string
        EventAttendeeEdit: string
        EventAttendeeView: string
        EventAttendeeLogsView: string
    }
}

export default Vue.extend({
    name: 'adminEventAttendeeView',
    components: {
        NavBarComponent,
        CopyButtonComponent,
    },
    data: (): Data => ({
        eventId: null,
        attendee: null,

        loading: true,

        BASE_URL,
        ROUTES: {
            EventView: Routes.EventView,
            EventAttendeeEdit: Routes.EventAttendeeEdit,
            EventAttendeeView: Routes.EventAttendeeView,
            EventAttendeeLogsView: Routes.EventAttendeeLogsView
        }
    }),
    methods: {
        async loadAttendee(eventId, attendeeId) {
            // reset
            this.loading = true
            this.eventId = null
            this.attendee = null

            // load attendee
            this.eventId = eventId
            this.attendee = await getAttendee(eventId, attendeeId)

            this.loading = false
        },
    },
    beforeRouteEnter(to, from, next) {
        console.log('enter')
        console.log(to.params.eventId)
        console.log(to.params.attendeeId)

        next((vm: any) => {
            vm.loadAttendee(to.params.eventId, to.params.attendeeId)
        })
    },
    beforeRouteUpdate(to, from, next) {
        console.log('update')
        console.log(to.params.eventId)
        console.log(to.params.attendeeId)

        next((vm: any) => {
            vm.loadAttendee(to.params.eventId, to.params.attendeeId)
        })
    },
})

