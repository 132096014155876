





































// @ is an alias to /src
import NavBarComponent from '@/admin/components/NavBarComponent.vue'
import CopyButtonComponent from '@/admin/components/CopyButtonComponent.vue'

import Vue from 'vue'
import { resolveAPIKey } from '@/admin/firebase/api'

interface Data {
    apikey: string | null
    loading: boolean
}

export default Vue.extend({
    name: 'adminApi',
    components: {
        NavBarComponent,
        CopyButtonComponent
    },
    data: (): Data => ({
        apikey: null,
        loading: true
    }),
    async created() {
        this.apikey = await resolveAPIKey()

        this.loading = false
    }
    ,
})

