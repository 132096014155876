import firebase from 'firebase'
// These imports load individual services into the firebase namespace.
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import { COLLECTION_USERS } from '@/config'
import { AdminUser, AdminChallenge } from '@/admin/types'
import { Message } from '@/client/types';
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import dayjs from 'dayjs';
import Timestamp = firebase.firestore.Timestamp;

function loadApp(): Promise<firebase.app.App> {

    const appPromise: Promise<firebase.app.App> = new Promise(function(resolve, reject) {

        try {

            fetch('/__/firebase/init.json').then(async response => {
                const json = await response.json()
                const app = firebase.initializeApp(json)

                if(process.env.NODE_ENV === 'development') {
                    console.log('app dev settings')
                    firebase.firestore().settings({
                        host: "localhost:5002",
                        ssl: false
                    })
                    // firebase.auth().useEmulator('http://localhost:9099/');
                    firebase.functions().useEmulator('localhost', 5001);
                }

                // console.log('app created')
                // console.log(json)
                // console.log(app)
                resolve(app)
            });

        } catch (e) {
            // console.log(e)
            reject()
        }

    })

    return appPromise
}

const appPromise: Promise<firebase.app.App> = loadApp()

export async function getApp(): Promise<firebase.app.App> {
    return appPromise
}


// const app = firebase.initializeApp({
//     apiKey: "AIzaSyAAdmgKqmSE5a-sZPaB_SKpOosJiKHknSE",
//     authDomain: "pxl-videoplatform.firebaseapp.com",
//     databaseURL: "https://pxl-videoplatform.firebaseio.com",
//     projectId: "pxl-videoplatform",
//     storageBucket: "pxl-videoplatform.appspot.com",
//     messagingSenderId: "378662921384",
//     appId: "1:378662921384:web:368d366109a915f0494e9d"
// })

export async function getAdminUser(): Promise<firebase.User> {

    await getApp()

    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
            if(user != null) {
                const userToken = await user.getIdTokenResult()
                const claims = userToken['claims']

                if (claims?.privileges?.admin === true) {
                    unsubscribe()
                    resolve(user)
                } else {
                    reject(`User is not an admin`)
                }
            } else {
                unsubscribe()
                reject()
            }

        }, reject)
    })
}

export async function getAPIKey(userId: string): Promise<string> {

    await getApp()

    const userRef = await firebase.firestore().collection(COLLECTION_USERS).doc(userId).get()
    const user = userRef.data() as AdminUser

    return user.apikey
}

export async function signOut() {

    await getApp()

    await firebase.auth().signOut()
}

export function eventMessageFromFirebase(messageDoc: DocumentSnapshot): Message {
    const data: any = messageDoc.data()

    return {
        id: messageDoc.id,
        attendeeId: data.attendeeId,
        message: data.message,
        name: data.name,
        date: timestampToDateString(data.date)
    }
}

export function timestampToDateString(date: Timestamp): string {
    return dayjs(date.toDate()).format()
}