




















































































































































































































































import Vue from 'vue'

import NavBarComponent from '@/admin/components/NavBarComponent.vue'
import CopyButtonComponent from '@/admin/components/CopyButtonComponent.vue'

import {
  AdminChallenge,
  AdminEvent,
  AdminEventPoll,
  CertificateSetting
} from '@/admin/types'
import {
  BASE_URL,
  CHALLENGE_ADMIN_DEBOUNCE
} from '@/config'

import {
  createPollChallenge,
  createPresenceChallenge,
  createQuestionChallenge,
  getEvent,
  getPresenceChallenges
} from '@/admin/firebase/api'
import { Routes } from '@/admin/config'
import dayjs from 'dayjs';
import ChatViewerComponent from '@/admin/views/components/ChatViewerComponent.vue';

interface Data {
        event: AdminEvent | null
        event_status: 'before' | 'during' | 'after'

        certificate_settings__started_viewing_A_certificate: CertificateSetting.started_viewing_A_certificate,
        certificate_settings__based_on_challenges_and_question: CertificateSetting.based_on_challenges_and_question,



        loading: boolean

        challenges: AdminChallenge[]
        ongoing_challenge: boolean

        started_polls: string[]

        has_valid_question: boolean

        BASE_URL: string
        ROUTES: {
            EventEdit: string
            EventAttendees: string
            EventView: string
        }
    }

    export default Vue.extend({
        name: 'adminEventView',
        components: {
            ChatViewerComponent,
            NavBarComponent,
            CopyButtonComponent,
        },
        data: (): Data => ({
            event: null,
            event_status: 'before',

            certificate_settings__started_viewing_A_certificate: CertificateSetting.started_viewing_A_certificate,
            certificate_settings__based_on_challenges_and_question: CertificateSetting.based_on_challenges_and_question,

            loading: true,

            challenges: [],
            ongoing_challenge: false,

            started_polls: [],

            has_valid_question: false,

            BASE_URL,
            ROUTES: {
                EventEdit: Routes.EventEdit,
                EventAttendees: Routes.EventAttendees,
                EventView: Routes.EventView
            }
        }),
        methods: {
            async loadEvent(id) {

                try {
                    this.event = await getEvent(id)

                    if(dayjs().isAfter(dayjs(this.event.start_at))) {
                      this.event_status = 'during'
                    }

                    // check if there is a question
                    if(this.event.question && this.event.answer) {
                      this.has_valid_question = true;
                    }

                    this.challenges = await getPresenceChallenges(this.event.id as string)

                    // check if challenge is ongoing
                    if(this.challenges.length > 0) {
                      const last_challenge = this.challenges[this.challenges.length - 1]
                      const minutes_ago = dayjs().diff(last_challenge.created_at, 'minutes')

                      if(minutes_ago < CHALLENGE_ADMIN_DEBOUNCE) {
                        this.ongoing_challenge = true

                        const minutes_to_go = CHALLENGE_ADMIN_DEBOUNCE - minutes_ago

                        setTimeout(() => {
                          this.ongoing_challenge = false
                        }, minutes_to_go * 60 * 1000)
                      }
                    }
                } catch (e) {
                    // pass
                    console.log(e)
                }

                this.loading = false
            },
            async createPresenceChallenge() {

                if(this.ongoing_challenge || this.event === null) return

                try {
                    this.ongoing_challenge = true
                    await createPresenceChallenge(this.event.id as string)

                    this.challenges.push({
                      created_at: dayjs().format()
                    })

                    setTimeout(() => {
                      this.ongoing_challenge = false
                    }, CHALLENGE_ADMIN_DEBOUNCE * 60 * 1000)
                } catch (e) {
                    // pass
                    this.ongoing_challenge = false
                }

            },
            async createQuestionChallenge() {

              if(this.event === null) return

                try {
                  await createQuestionChallenge(this.event.id as string)
                } catch (e) {
                    // pass
                }

            },
            async createPollChallenge(poll: AdminEventPoll) {

              if(this.event === null) return

              try {
                await createPollChallenge(this.event.id as string, poll.question, poll.options.map(p => p.option))

                this.started_polls.push(poll.question)

                poll.asked_at = dayjs().format()

              } catch (e) {
                // pass
              }

            },
        },
        beforeRouteEnter(to, from, next) {
            console.log('enter')
            console.log(to.params.id)

            next((vm: any) => {
                vm.loadEvent(to.params.id)
            })
        },
        beforeRouteUpdate(to, from, next) {
            console.log('update')
            console.log(to.params.id)

            next((vm: any) => {
                vm.loadEvent(to.params.id)
            })
        },
    })

