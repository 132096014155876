
































import Vue from 'vue'
import LogoutComponent from '@/admin/components/LogoutComponent.vue'
import { Routes } from '@/admin/config'

interface Data {
    ROUTES: {
        Events: string
        Locations: string
        Api: string
    }
}

export default Vue.extend({
    name: 'navBar',
    components: {
        LogoutComponent
    },
    data: (): Data => ({
        ROUTES: {
            Events: Routes.EventsCurrent,
            Locations: Routes.Locations,
            Api: Routes.Api,
        }
    }),
})

