export const REGION = 'europe-west3'

export const COLLECTION_USERS = 'users'
export const COLLECTION_LOCATIONS = 'locations'
export const COLLECTION_EVENTS = 'events'
export const COLLECTION_EVENTS_ATTENDEES = 'attendees'
export const COLLECTION_EVENTS_MESSAGES = 'messages'
export const COLLECTION_EVENTS_CHALLENGES = 'challenges'

export const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:5005' : 'https://pxl.live'

export const MESSAGE_LENGTH = 400

export const CHALLENGE_ADMIN_DEBOUNCE = 20 // minutes
export const CHALLENGE_CLIENT_WINDOW = 15 // minutes
export const CHALLENGE_CLIENT_TIMER = 60 // seconds
export const CHALLENGE_CLIENT_GRACE = 3 // seconds over the timer
