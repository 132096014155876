export const enum Routes {
    Login = 'login',

    Api = 'api',

    EventsCurrent = 'events-current',
    Events = 'events',
    EventView = 'event-view',
    EventEdit = 'event-edit',
    EventCreate = 'event-create',

    EventAttendees = 'event-attendees',
    EventAttendeeView = 'event-attendee-view',
    EventAttendeeEdit = 'event-attendee-edit',
    EventAttendeeCreate = 'event-attendee-create',
    EventAttendeeLogsView = 'event-attendee-logs-view',

    Locations = 'locations',
    LocationEdit = 'location-edit',
    LocationCreate = 'location-create',
}
