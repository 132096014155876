

























































































import Vue from 'vue'

import NavBarComponent from '@/admin/components/NavBarComponent.vue'
import { AdminAttendee } from '@/admin/types'
import { getAttendee, updateAttendee } from '@/admin/firebase/api'
import { Routes } from '@/admin/config'


interface Data {
    eventId: string
    id: string

    loading: boolean
    saving: boolean
    valid: boolean

    ROUTES: {
        EventView: string
        EventAttendeeView: string
        EventAttendeeEdit: string
        EventAttendeeLogsView: string
    }

    name: string
    email: string
}

export default Vue.extend({
    name: 'adminEventAttendeeEdit',
    data: (): Data => ({
        eventId: '',
        id: '',

        loading: true,
        saving: false,
        valid: true,

        ROUTES: {
            EventView: Routes.EventView,
            EventAttendeeView: Routes.EventAttendeeView,
            EventAttendeeEdit: Routes.EventAttendeeEdit,
            EventAttendeeLogsView: Routes.EventAttendeeLogsView
        },

        name: '',
        email: '',
    }),
    components: {
        NavBarComponent,
    },
    methods: {
        async loadAttendee(eventId, attendeeId) {
            // reset
            this.loading = true
            this.eventId = ''
            this.id = ''

            // load attendee
            try {
                this.eventId = eventId
                this.id = attendeeId
                const attendee = await getAttendee(eventId, attendeeId)

                this.name = attendee.name
                this.email = attendee.email

            } catch (e) {
                // pass
            }

            this.loading = false
        },
        async submit() {
            const isValid = (this.$refs.form as any).validate()

            if (isValid) {
                this.saving = true

                console.log('submit data')

                let attendee: AdminAttendee = {
                    id: this.id,
                    name: this.name,
                    email: this.email,
                }

                await updateAttendee(this.eventId, attendee)

                this.saving = false

                this.$router.push({name: Routes.EventAttendeeView, params: {eventId: this.eventId, attendeeId: this.id}})
            }

        },
    },
    beforeRouteEnter(to, from, next) {
        console.log('enter')
        console.log(to.params.eventId)
        console.log(to.params.attendeeId)

        next((vm: any) => {
            vm.loadAttendee(to.params.eventId, to.params.attendeeId)
        })
    },
    beforeRouteUpdate(to, from, next) {
        console.log('update')
        console.log(to.params.eventId)
        console.log(to.params.attendeeId)

        next((vm: any) => {
            vm.loadAttendee(to.params.eventId, to.params.attendeeId)
        })
    },
})

