export declare type Priviliges = {
    admin: boolean
}
export declare type AdminUser = {
    name: string,
    email: string
    apikey: string
}

export declare type AdminEventPollOption = {
    option: string
    count: number
}

export declare type AdminEventPoll = {
    question: string
    options: AdminEventPollOption[]
    asked_at?: string // date
}

// different kinds of settings to determine the certificate granted
export enum CertificateSetting {
    // A grade certificate: started viewing
    // No B grade
    started_viewing_A_certificate = 'started_viewing_A_certificate',

    // A grade certificate: At least 1 completed challenges and question answered
    // (does not need to be correctly answered)
    // B grade certificate: At least 1 completed challenges
    // (default)
    based_on_challenges_and_question = 'based_on_challenges_and_question',
}

export declare type AdminEvent = {
    id?: string
    name: string

    location: string
    player_id?: string
    recording_id?: string

    start_at: string // date
    end_at?: string // date

    available_until?: string  // date

    certificate_setting?: CertificateSetting

    question?: string
    possible_answers?: string[]
    answer?: string

    polls: AdminEventPoll[]

    is_public?: boolean

    has_chat: boolean
}

export enum Certificate {
    A = 'A',
    B = 'B',
    NONE = ''
}

export declare type AdminAttendee = {
    id?: string
    name: string
    email: string

    event_url?: string

    attended?: boolean

    started_viewing_at?: string // date
    ended_viewing_at?: string // date

    first_opened_event_at?: string // date

    answered?: boolean
    answered_question_correctly?: boolean

    completed_challenges?: string[]

    completed_polls?: {
        id: string,
        question: string,
        option: string
    }[]

    certificate?: Certificate
}

export declare type AdminAttendeeLog = {
    id?: string
    created_at?: string // date
    message: string
}

export declare type AdminChallenge = {
    id?: string,
    created_at: string // date,
}

export declare type AdminLocation = {
    id: string
    player_id: string
}

export declare type KalturaLivestream = {
    id: string
    name: string
    primaryBroadcastingUrl: string
    streamName: string
    recordedEntryId: string
}
