import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import { getAdminUser } from '@/admin/firebase'

import { Routes } from '@/admin/config'

import LoginPage from '@/admin/views/LoginPage.vue'
import AdminEventsCurrentPage from '@/admin/views/AdminEventsCurrentPage.vue'
import AdminEventsPage from '@/admin/views/AdminEventsPage.vue'
import AdminEventEditPage from '@/admin/views/AdminEventEditPage.vue'
import AdminEventViewPage from '@/admin/views/AdminEventViewPage.vue'
import AdminEventCreatePage from '@/admin/views/AdminEventCreatePage.vue'
import AdminApiPage from '@/admin/views/AdminApiPage.vue'
import AdminLocationsPage from '@/admin/views/AdminLocationsPage.vue'
import AdminLocationCreatePage from '@/admin/views/AdminLocationCreatePage.vue'
import AdminLocationEditPage from '@/admin/views/AdminLocationEditPage.vue'
import AdminEventAttendeesPage from '@/admin/views/AdminEventAttendeesPage.vue'
import AdminEventAttendeeViewPage from '@/admin/views/AdminEventAttendeeViewPage.vue'
import AdminEventAttendeeEditPage from '@/admin/views/AdminEventAttendeeEditPage.vue'
import AdminEventAttendeeCreatePage from '@/admin/views/AdminEventAttendeeCreatePage.vue'
import AdminEventAttendeeLogsViewPage from '@/admin/views/AdminEventAttendeeLogsViewPage.vue';

Vue.use(VueRouter)



const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: Routes.Login,
        component: LoginPage,
    },
    {
        path: '/events-current',
        name: Routes.EventsCurrent,
        component: AdminEventsCurrentPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/events',
        name: Routes.Events,
        component: AdminEventsPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/event/view/:id',
        name: Routes.EventView,
        component: AdminEventViewPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/event/edit/:id',
        name: Routes.EventEdit,
        component: AdminEventEditPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/event/create',
        name: Routes.EventCreate,
        component: AdminEventCreatePage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/events/:eventId/attendees',
        name: Routes.EventAttendees,
        component: AdminEventAttendeesPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/events/:eventId/attendees/view/:attendeeId',
        name: Routes.EventAttendeeView,
        component: AdminEventAttendeeViewPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/events/:eventId/attendees/logs/:attendeeId',
        name: Routes.EventAttendeeLogsView,
        component: AdminEventAttendeeLogsViewPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/events/:eventId/attendees/edit/:attendeeId',
        name: Routes.EventAttendeeEdit,
        component: AdminEventAttendeeEditPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/events/:eventId/attendees/create',
        name: Routes.EventAttendeeCreate,
        component: AdminEventAttendeeCreatePage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/api',
        name: Routes.Api,
        component: AdminApiPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/locations',
        name: Routes.Locations,
        component: AdminLocationsPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/locations/edit/:id',
        name: Routes.LocationEdit,
        component: AdminLocationEditPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/locations/create',
        name: Routes.LocationCreate,
        component: AdminLocationCreatePage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '*',
        component: AdminEventsCurrentPage,
        meta: {
            requiresAuth: true
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth) {

    try {
      await getAdminUser()
      next()
    } catch (e) {
      // TODO logging
      console.log('false, login please')
      next({name: Routes.Login})
    }


  } else {
    next()
  }
})

export default router
