











import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'

// import firebase from '@/firebase'
import * as firebaseui from 'firebaseui'
import Vue from 'vue'
import { Routes } from '@/admin/config'
import {
  getApp,
  signOut
} from '@/admin/firebase'
import { removeAPIKey, resolveAPIKey } from '@/admin/firebase/api'

export default Vue.extend({
    name: 'login',
    computed: {},
    data() {
        return {
            ui: null
        }
    }
    ,
    components: {},
    methods: {
        startLogin() {
            const uiConfig = {
                callbacks: {
                    signInSuccessWithAuthResult(authResult: any, redirectUrl?: string): boolean {
                        console.log('signInSuccessWithAuthResult')

                        return false; // no redirect
                    },
                    uiShown: () => {
                        console.log('uiShown')
                        // document.getElementById('loader').style.display = 'none';
                    },
                },
                // signInSuccessUrl: '/',
                signInFlow: 'popup',
                signInOptions: [
                    // Leave the lines as is for the providers you want to offer your users.
                    // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                    // firebaseui.auth.FacebookAuthProvider.PROVIDER_ID,
                    // firebaseui.auth.TwitterAuthProvider.PROVIDER_ID,
                    // firebaseui.auth.GithubAuthProvider.PROVIDER_ID,
                    {
                        provider: (firebase.auth as any).EmailAuthProvider.PROVIDER_ID,
                        // Whether the display name should be displayed in the Sign Up page.
                        requireDisplayName: true
                    },
                    // firebaseui.auth.PhoneAuthProvider.PROVIDER_ID,
                    // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
                ],
                credentialHelper: firebaseui.auth.CredentialHelper.NONE,
                // tosUrl and privacyPolicyUrl accept either url string or a callback
                // function.
                // Terms of service url/callback.
                tosUrl: '',
                // Privacy policy url/callback.
                privacyPolicyUrl: function () {
                    window.location.assign('');
                }
            };

            // Initialize the FirebaseUI Widget using Firebase.
            if (!this.ui) {
                this.ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth()) as any;
            }

            // The start method will wait until the DOM is loaded.
            // if (ui.isPendingRedirect()) {
            (this.ui as any).start('#firebaseui-auth-container', uiConfig);
            // }
        },

        async checkUser() {

            console.log('check user')

            const app = await getApp()
            console.log('app received')

            firebase.auth().onAuthStateChanged(async (user) => {
                if (user != null) {
                    // User is signed in.
                    // const displayName = user.email;
                    // var email = user.email;
                    // var emailVerified = user.emailVerified;
                    // var photoURL = user.photoURL;
                    // var uid = user.uid;
                    // var phoneNumber = user.phoneNumber;
                    // var providerData = user.providerData;

                    const userToken = await user.getIdTokenResult()
                    const claims = userToken['claims']

                    if (claims?.privileges?.admin === true) {
                        console.log(`${user.email} logged in`)

                        // set global API key
                        resolveAPIKey()

                        await this.$router.push({name: Routes.EventsCurrent})
                    } else {
                        console.error('User is not an admin') // TODO beter error handling
                        await signOut()
                        this.startLogin()
                    }
                } else {
                    // User is signed out.
                    console.log('Signed out');

                    // reset key
                    removeAPIKey()

                    this.startLogin()
                }

            }, (error) => {
                console.log(error);
            });
        }
    },

    mounted() {
        this.checkUser()
    }
})

