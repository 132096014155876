







import Vue from 'vue'

export default Vue.extend({
    name: 'copyButton',
    props: [
        'label',
        'value',
    ],
    methods: {
        copyValue: function () {
            navigator.clipboard.writeText(this.$props.value)
        },
    },
})
