



























































































































































































































































































































































































































































import Vue from 'vue'

import dayjs from 'dayjs'
import NavBarComponent from '@/admin/components/NavBarComponent.vue'
import {
  AdminEvent,
  CertificateSetting
} from '@/admin/types'
import {
  getEvent,
  updateEvent,
  getLocations
} from '@/admin/firebase/api'
import { Routes } from '@/admin/config'


interface Data {
  id: string
  loading: boolean
  saving: boolean
  valid: boolean

  ROUTES: {
    EventEdit: string
    EventAttendees: string
    EventView: string
  }

  name: string
  location: string
  locations: Array<{ text: string, value: string }>

  startAtDate: any
  startAtDateMenu: boolean
  startAtTime: any
  startAtTimeMenu: boolean

  endAtDate: any
  endAtDateMenu: boolean
  endAtTime: any
  endAtTimeMenu: boolean

  availableUntilDate: any
  availableUntilDateMenu: boolean
  availableUntilTime: any
  availableUntilTimeMenu: boolean

  certificate_setting: CertificateSetting
  certificate_setting_options: {label: string, value: CertificateSetting}[]
  certificate_settings__started_viewing_A_certificate: CertificateSetting.started_viewing_A_certificate
  certificate_settings__based_on_challenges_and_question: CertificateSetting.based_on_challenges_and_question

  question: string
  possible_answers: Array<{ value: string }>
  answer: string

  polls: Array<{
    question: string
    options: Array<{ value: string }>
  }>

  is_public: boolean

  has_chat: boolean,
}

export default Vue.extend({
  name: 'adminEventCreate',
  data: (): Data => ({
    id: '',
    loading: true,
    saving: false,
    valid: true,

    ROUTES: {
      EventEdit: Routes.EventEdit,
      EventAttendees: Routes.EventAttendees,
      EventView: Routes.EventView
    },

    name: '',
    location: '',
    locations: [],

    startAtDate: null,
    startAtDateMenu: false,
    startAtTime: null,
    startAtTimeMenu: false,

    endAtDate: null,
    endAtDateMenu: false,
    endAtTime: null,
    endAtTimeMenu: false,

    availableUntilDate: null,
    availableUntilDateMenu: false,
    availableUntilTime: null,
    availableUntilTimeMenu: false,

    certificate_setting: CertificateSetting.based_on_challenges_and_question,
    certificate_setting_options: [
      {label: 'Op basis van aanwezigheidscontroles en de bevestigingsvraag', value: CertificateSetting.based_on_challenges_and_question},
      {label: 'Kijken is voldoende', value: CertificateSetting.started_viewing_A_certificate}
    ],
    certificate_settings__started_viewing_A_certificate: CertificateSetting.started_viewing_A_certificate,
    certificate_settings__based_on_challenges_and_question: CertificateSetting.based_on_challenges_and_question,

    question: '',
    possible_answers: [],
    answer: '',

    polls: [],

    is_public: false,

    has_chat: true,
  }),
  computed: {
    startAtDateFormatted() {
      const date = dayjs(this.startAtDate as any, 'YYY-MM-DD')

      return date.isValid() ? date.format('DD/MM/YYYY') : null
    },
    endAtDateFormatted() {
      const date = dayjs(this.endAtDate as any, 'YYY-MM-DD')

      return date.isValid() ? date.format('DD/MM/YYYY') : null
    },
    availableUntilDateFormatted() {
      const date = dayjs(this.availableUntilDate as any, 'YYY-MM-DD')

      return date.isValid() ? date.format('DD/MM/YYYY') : null
    },
  },
  components: {
    NavBarComponent,
  },
  methods: {
    async loadEvent(id) {

      const locations = await getLocations()

      this.locations = locations.map(l => {
        return {
          text: l.id,
          value: l.id,
        }
      })

      try {
        this.id = id
        const event = await getEvent(id)

        this.name = event.name
        this.location = event.location

        this.startAtDate = dayjs(event.start_at).format('YYYY-MM-DD')
        this.startAtTime = dayjs(event.start_at).format('HH:mm')
        this.endAtDate = event.end_at ? dayjs(event.end_at).format('YYYY-MM-DD') : ''
        this.endAtTime = event.end_at ? dayjs(event.end_at).format('HH:mm') : ''
        this.availableUntilDate = event.available_until ? dayjs(event.available_until).format('YYYY-MM-DD') : ''
        this.availableUntilTime = event.available_until ? dayjs(event.available_until).format('HH:mm') : ''

        this.certificate_setting = event.certificate_setting as CertificateSetting,

        this.question = event.question ? event.question : ''
        this.possible_answers = event.possible_answers ? event.possible_answers.map(v => {
          return {value: v}
        }) : []
        this.answer = event.answer ? event.answer : ''

        this.polls = event.polls ? event.polls.map(p => {
          return {
            question: p.question,
            options: p.options.map(o => {
              return {
                value: o.option
              }
            })
          }
        }) : []

        this.is_public = event.is_public as boolean

        this.has_chat = event.has_chat as boolean
      } catch (e) {
        // pass
      }

      this.loading = false
    },
    async submit() {
      const isValid = (this.$refs.form as any).validate()

      if (isValid) {
        this.saving = true

        console.log('submit data')

        const availableUntil = dayjs(`${this.availableUntilDate} ${this.availableUntilTime}`, 'YYYY-MM-DD HH:mm')

        let event: AdminEvent = {
          id: this.id,
          name: this.name,
          location: this.location,

          start_at: dayjs(`${this.startAtDate} ${this.startAtTime}`, 'YYYY-MM-DD HH:mm').format(),
          end_at: dayjs(`${this.endAtDate} ${this.endAtTime}`, 'YYYY-MM-DD HH:mm').format(),
          available_until: availableUntil.isValid() ? availableUntil.format() : undefined,

          certificate_setting: this.certificate_setting,

          question: this.question !== '' ? this.question : undefined,
          possible_answers: this.possible_answers
              .filter(a => a.value && a.value !== '')
              .map(v => v.value),
          answer: this.answer !== '' ? this.answer : undefined,

          polls: this.polls
              .filter(p => p.question && p.question !== '')
              .map(p => {
            return {
              question: p.question,
              options: p.options
                  .filter(o => o.value && o.value !== '')
                  .map(o => {
                return {
                  option: o.value,
                  count: 0
                }
              })
            }
          }),

          is_public: this.is_public,

          has_chat: this.has_chat
        }

        await updateEvent(event)

        this.saving = false

        this.$router.push({name: Routes.EventView, params: {id: this.id}})
      }

    },
  },
  beforeRouteEnter(to, from, next) {
    console.log('enter')
    console.log(to.params.id)

    next((vm: any) => {
      vm.loadEvent(to.params.id)
    })
  },
  beforeRouteUpdate(to, from, next) {
    console.log('update')
    console.log(to.params.id)

    next((vm: any) => {
      vm.loadEvent(to.params.id)
    })
  },
})

