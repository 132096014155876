import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: "https://dc79736242fd440aabd29a71d02c4c46@o541557.ingest.sentry.io/5660629",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

Vue.config.productionTip = false

import dayjs from 'dayjs'
import 'dayjs/locale/nl'

// internationalitzaion
dayjs.locale('nl') // use loaded locale globally

// add filter
Vue.filter('dayjs', (value: any, format: any) => {
  const d = dayjs(value);

  if (!d.isValid()) {
    console.error('Could not parse into date: ' + value)
    return value
  }

  return d.format(format)
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
