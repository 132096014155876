















































































































































// @ is an alias to /src
import NavBarComponent from '@/admin/components/NavBarComponent.vue'
import CopyButtonComponent from '@/admin/components/CopyButtonComponent.vue'

import Vue from 'vue'
import { deleteAttendee, getAttendees, getEvent } from '@/admin/firebase/api'
import { AdminAttendee, AdminEvent } from '@/admin/types'
import { Routes } from '@/admin/config'
import { Attendee } from '@/client/types';

interface Data {
    event: AdminEvent | null
    attendees: AdminAttendee[]

    loading: boolean

    ROUTES: {
        EventEdit: string
        EventAttendees: string
        EventView: string
        EventAttendeeCreate: string
        EventAttendeeEdit: string
        EventAttendeeView: string
    }
}

export default Vue.extend({
    name: 'adminEventAttendees',
    components: {
        NavBarComponent,
        CopyButtonComponent,
    },
    data: (): Data => ({
        event: null,
        attendees: [],

        loading: true,

        ROUTES: {
            EventEdit: Routes.EventEdit,
            EventAttendees: Routes.EventAttendees,
            EventView: Routes.EventView,
            EventAttendeeCreate: Routes.EventAttendeeCreate,
            EventAttendeeEdit: Routes.EventAttendeeEdit,
            EventAttendeeView: Routes.EventAttendeeView,
        }
    }),
    methods: {
      async deleteAttendee(attendee: AdminAttendee) {
        console.log(`Deleting attendee ${attendee.id}`)

        if (this.event != null && confirm(`Deelnemer '${attendee.name}' verwijderen?`)) {

          await deleteAttendee(this.event.id as string, attendee.id as string)

          this.attendees = this.attendees.filter(a => a !== attendee)
          console.log(`Deleted attendee ${attendee.id}`)

        } else {
          console.log('Cancelled')
        }
      },
        async loadEvent(id) {
            // reset
            this.loading = true
            this.event = null
            this.attendees = []

            // load event and attendees
            this.event = await getEvent(id)
            this.attendees = await getAttendees(this.event.id as string)

            this.loading = false
        },
    },
    beforeRouteEnter(to, from, next) {
        console.log('enter')
        console.log(to.params.eventId)

        next((vm: any) => {
            vm.loadEvent(to.params.eventId)
        })
    },
    beforeRouteUpdate(to, from, next) {
        console.log('update')
        console.log(to.params.eventId)

        next((vm: any) => {
            vm.loadEvent(to.params.eventId)
        })
    },
})

