





































































import Vue from 'vue'

import NavBarComponent from '@/admin/components/NavBarComponent.vue'
import { AdminAttendee } from '@/admin/types'
import { createAttendee } from '@/admin/firebase/api'
import { Routes } from '@/admin/config'


interface Data {
    eventId: string

    loading: boolean
    saving: boolean
    valid: boolean

    name: string
    email: string
}

export default Vue.extend({
    name: 'adminEventAttendeeCreate',
    data: (): Data => ({
        eventId: '',

        loading: true,
        saving: false,
        valid: true,

        name: '',
        email: '',
    }),
    components: {
        NavBarComponent,
    },
    methods: {
        async setEventId(eventId) {
            // reset
            this.loading = true
            this.eventId = ''

            // load attendee
            this.eventId = eventId

            this.loading = false
        },
        async submit() {
            const isValid = (this.$refs.form as any).validate()

            if (isValid) {
                this.saving = true

                console.log('Create attendee')

                let attendee: AdminAttendee = {
                    name: this.name,
                    email: this.email,
                }

                await createAttendee(this.eventId, attendee)

                this.saving = false

                this.$router.push({name: Routes.EventAttendees, params: {eventId: this.eventId}})
            }

        },
    },
    beforeRouteEnter(to, from, next) {
        console.log('enter')
        console.log(to.params.eventId)

        next((vm: any) => {
            vm.setEventId(to.params.eventId)
        })
    },
    beforeRouteUpdate(to, from, next) {
        console.log('update')
        console.log(to.params.eventId)

        next((vm: any) => {
            vm.setEventId(to.params.eventId)
        })
    },
})

