import firebase from 'firebase'
import 'firebase/firestore'
import {
    Message,
    PXLEvent
} from '@/client/types';
import {
    COLLECTION_EVENTS,
    COLLECTION_EVENTS_MESSAGES
} from '@/config';
import Unsubscribe = firebase.Unsubscribe;
import {
    eventMessageFromFirebase
} from '@/admin/firebase/index';
import { getApp } from '@/admin/firebase/index';


export async function onNewMessage(eventId, handleNewMessage: (message: Message) => void): Promise<Unsubscribe> {

    const firebaseApp = await getApp()

    return firebase.firestore()
        .collection(COLLECTION_EVENTS).doc(eventId)
        .collection(COLLECTION_EVENTS_MESSAGES)
        .orderBy('date', 'asc')
        .onSnapshot(snapshot => {

            // add new messages
            snapshot.docChanges().forEach((change) => {

                console.log('snapshot')

                if (change.type === 'added') {

                    handleNewMessage(eventMessageFromFirebase(change.doc))

                }
            })

        })

}
