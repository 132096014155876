
































































































































































// @ is an alias to /src
import NavBarComponent from '@/admin/components/NavBarComponent.vue'

import Vue from 'vue'
import {
  getEvents,
  deleteEvent,
  getEventsFrom
} from '@/admin/firebase/api'
import { AdminEvent } from '@/admin/types'
import { Routes } from '@/admin/config'
import dayjs from 'dayjs'

interface Data {
    upcomingEvents: Array<AdminEvent>
    pastEvents: Array<AdminEvent>

    loading: boolean

    ROUTES: {
        Events: string
        EventCreate: string
        EventEdit: string
        EventView: string
        EventAttendees: string
    }
}

export default Vue.extend({
    name: 'adminEvents',
    components: {
        NavBarComponent
    },
    data: (): Data => ({
        upcomingEvents: [],
        pastEvents: [],

        loading: true,

        ROUTES: {
            Events: Routes.Events,
            EventCreate: Routes.EventCreate,
            EventEdit: Routes.EventEdit,
            EventView: Routes.EventView,
            EventAttendees: Routes.EventAttendees,
        }
    }),
    methods: {
      async deleteEvent(event: AdminEvent) {
        console.log(`Deleting event ${event.id}`)

        if(confirm(`Event '${event.name}' verwijderen?`)) {

          await deleteEvent(event.id as string)

          this.upcomingEvents = this.upcomingEvents.filter(e => e !== event)
          this.pastEvents = this.pastEvents.filter(e => e !== event)
          console.log(`Deleted event ${event.id}`)

        } else {
          console.log('Cancelled')
        }

      }
    },
    async created() {
        const events = await getEventsFrom(dayjs().subtract(7, 'days').toDate())

        this.upcomingEvents = []
        this.pastEvents = []

        const today = dayjs().startOf('day')

        events.forEach((event: AdminEvent) => {

          if(dayjs(event.start_at).isBefore(today)) {
              this.pastEvents.push(event)
          } else {
              this.upcomingEvents.push(event)
          }

        })

        this.pastEvents.sort((a, b) => {
            return dayjs(a.start_at).isAfter(dayjs(b.start_at)) ? -1 : 1
        })
        this.upcomingEvents.sort((a, b) => {
            return dayjs(a.start_at).isBefore(dayjs(b.start_at)) ? -1 : 1
        })


        this.loading = false
    }
    ,
})

