import {
    getAdminUser,
    getAPIKey,
    getApp
} from '@/admin/firebase/index'
import {
    AdminAttendee,
    AdminAttendeeLog,
    AdminChallenge,
    AdminEvent,
    AdminLocation,
    KalturaLivestream
} from '@/admin/types'
import dayjs from 'dayjs';

declare type Method = 'GET' | 'POST' | 'PUT' | 'DELETE'

let apikey: string | null = null

async function doApiCall(url, method: Method = 'GET', body: any = undefined) {

    await getApp()

    const apikey = await resolveAPIKey()

    const options: any = {
        method: method,
        headers: {
            'Authorization': `Api-Key ${apikey}`,
        },
    }

    if (body) {
        options.headers['Content-Type'] = 'application/json'
        options.body = JSON.stringify(body)
    }

    const result = await fetch(`${process.env.VUE_APP_API_URL}${url}`, options)

    if(result.status === 401) {
        throw Error('API Key not authorized!')
    }

    return result
}

export async function resolveAPIKey(): Promise<string> {

    if(apikey === null) {
        const user = await getAdminUser()
        try {
            console.log('Getting API key')
            const key = await getAPIKey(user.uid)
            apikey = key
        } catch(e) {
            console.error(`Get API key failed for user ${user.uid}`)
            throw e
        }
    }

    return apikey
}

export function removeAPIKey() {
    apikey = null
}

// EVENTS

export async function getEvents(): Promise<AdminEvent[]> {

    try {
        const response = await doApiCall('/events')
        return await response.json()
    } catch(e) {
        console.error('Get events failed')
        throw e
    }
}

export async function getEventsFrom(
    date: Date
): Promise<AdminEvent[]> {

    try {
        const response = await doApiCall(`/events?from=${dayjs(date).unix()}`)
        return await response.json()
    } catch(e) {
        console.error(`Get events from from ${dayjs(date).unix()}`)
        throw e
    }
}


export async function getEvent(id: string): Promise<AdminEvent> {

    try {
        const response = await doApiCall(`/events/${id}`)
        return await response.json()
    } catch(e) {
        console.error(`Get event failed ${id}`)
        throw e
    }
}

export async function createEvent(event: AdminEvent): Promise<AdminEvent> {

    try {
        const response = await doApiCall(`/events`, 'POST', event)
        return await response.json()
    } catch(e) {
        console.error('Creating event failed')
        console.error(event)
        throw e
    }
}

export async function updateEvent(event: AdminEvent): Promise<AdminEvent> {

    try {
        const response = await doApiCall(`/events/${event.id}`, 'PUT', event)
        return await response.json()
    } catch(e) {
        console.error('Updating event failed')
        console.error(event)
        throw e
    }
}

export async function deleteEvent(id: string): Promise<boolean> {

    try {
        const response = await doApiCall(`/events/${id}`, 'DELETE')
        return response.ok
    } catch(e) {
        console.error(`Deleting event failed ${id}`)
        throw e
    }
}

// ATTENDEES

export async function getAttendees(eventId: string): Promise<AdminAttendee[]> {

    try {
        const response = await doApiCall(`/events/${eventId}/attendees`)
        return await response.json()
    } catch(e) {
        console.error(`Get attendees failed for event ${eventId}`)
        throw e
    }
}


export async function getAttendee(eventId: string, attendeeId: string): Promise<AdminAttendee> {

    try {
        const response = await doApiCall(`/events/${eventId}/attendees/${attendeeId}`)
        return await response.json()
    } catch(e) {
        console.error(`Get attendee failed ${attendeeId} for event ${eventId}`)
        throw e
    }
}

export async function createAttendee(eventId: string, attendee: AdminAttendee): Promise<AdminAttendee> {

    try {
        const response = await doApiCall(`/events/${eventId}/attendees`, 'POST', attendee)
        return await response.json()
    } catch(e) {
        console.error(`Creating attendee failed for event ${eventId}`)
        console.error(attendee)
        throw e
    }
}

export async function updateAttendee(eventId: string, attendee: AdminAttendee): Promise<AdminAttendee> {

    try {
        const response = await doApiCall(`/events/${eventId}/attendees/${attendee.id}`, 'PUT', attendee)
        return await response.json()
    } catch(e) {
        console.error(`Updating attendee failed for event ${eventId}`)
        console.error(attendee)
        throw e
    }
}

export async function deleteAttendee(eventId: string, attendeeId: string): Promise<boolean> {

    try {
        const response = await doApiCall(`/events/${eventId}/attendees/${attendeeId}`, 'DELETE')
        return response.ok
    } catch(e) {
        console.error(`Deleting attendee failed ${attendeeId} for event ${eventId}`)
        throw e
    }
}

// ATTENDEE LOGS

export async function getAttendeeLogs(eventId: string, attendeeId: string): Promise<AdminAttendeeLog[]> {

    try {
        const response = await doApiCall(`/events/${eventId}/attendees/${attendeeId}/logs`)
        return await response.json()
    } catch(e) {
        console.error(`Get attendee logs failed for event ${eventId} attendee ${attendeeId}`)
        throw e
    }
}


// LOCATIONS

export async function getLocations(): Promise<AdminLocation[]> {

    try {
        const response = await doApiCall('/locations')
        return await response.json()
    } catch(e) {
        console.error('Get locations failed')
        throw e
    }
}

export async function getLocation(id: string): Promise<AdminLocation> {

    try {
        const response = await doApiCall(`/locations/${id}`)
        return await response.json()
    } catch(e) {
        console.error('Updating location failed')
        console.error(location)
        throw e
    }
}

export async function createLocation(location: AdminLocation): Promise<AdminLocation> {

    try {
        const response = await doApiCall(`/locations/${location.id}`, 'POST', location)
        return await response.json()
    } catch(e) {
        console.error('Updating location failed')
        console.error(location)
        throw e
    }
}

export async function updateLocation(location: AdminLocation): Promise<AdminLocation> {

    try {
        const response = await doApiCall(`/locations/${location.id}`, 'PUT', location)
        return await response.json()
    } catch(e) {
        console.error('Updating location failed')
        console.error(location)
        throw e
    }
}

export async function deleteLocation(id: string): Promise<boolean> {

    try {
        const response = await doApiCall(`/locations/${id}`, 'DELETE')
        return response.ok
    } catch(e) {
        console.error(`Deleting location failed ${id}`)
        throw e
    }
}

// LIVESTREAMS

export async function getLivestreams(): Promise<KalturaLivestream[]> {

    try {
        const response = await doApiCall('/livestreams')
        return await response.json()
    } catch(e) {
        console.error('Get livestreams failed')
        throw e
    }
}

// CHALLENGES

export async function createQuestionChallenge(event_id: string): Promise<void> {

    try {
        await doApiCall(`/challenge/question/start/${event_id}`, 'POST')
    } catch(e) {
        console.error('Starting challenge question failed')
        throw e
    }
}

export async function createPresenceChallenge(event_id: string): Promise<void> {

    try {
        await doApiCall(`/challenge/presence/start/${event_id}`, 'POST')
    } catch(e) {
        console.error('Starting presence challenge failed')
        throw e
    }
}

export async function getPresenceChallenges(event_id: string): Promise<AdminChallenge[]> {

    try {
        const response = await doApiCall(`/challenges/presence/${event_id}`)
        return await response.json()
    } catch(e) {
        console.error('Get presence challenges failed')
        throw e
    }
}

export async function createPollChallenge(event_id: string, question: string, options: string[]): Promise<void> {

    try {
        await doApiCall(`/challenge/poll/start/${event_id}`, 'POST', {
            question,
            options
        })
    } catch(e) {
        console.error('Starting poll challenge failed')
        throw e
    }
}
