






























































// @ is an alias to /src
import NavBarComponent from '@/admin/components/NavBarComponent.vue'

import Vue from 'vue'
import { getLocations } from '@/admin/firebase/api'
import { AdminLocation } from '@/admin/types'
import { getLivestreams } from '@/admin/firebase/api'
import { Routes } from '@/admin/config'

interface Data {
    locations: Array<AdminLocation>
    livestreams: Map<string, string>
    loading: boolean

    ROUTES: {
        LocationCreate: string
        LocationEdit: string
    }
}

export default Vue.extend({
    name: 'adminLocations',
    components: {
        NavBarComponent
    },
    data: (): Data => ({
        locations: [],
        livestreams: new Map(),
        loading: true,

        ROUTES: {
            LocationCreate: Routes.LocationCreate,
            LocationEdit: Routes.LocationEdit,
        }
    }),
    async created() {
        this.locations = await getLocations()

        let livestreams = await getLivestreams()

        livestreams.forEach(l => {
            this.livestreams.set(l.id, l.name)
        })

        this.loading = false
    }
    ,
})

