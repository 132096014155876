






































































import Vue from 'vue'

import NavBarComponent from '@/admin/components/NavBarComponent.vue'
import { AdminLocation } from '@/admin/types'
import { createLocation } from '@/admin/firebase/api'
import { Routes } from '@/admin/config'
import { getLivestreams } from '@/admin/firebase/api'

interface Data {
    loading: boolean
    saving: boolean
    valid: boolean

    id: string
    player_id: string
    livestreams: Array<{ text: string, value: string }>
}

export default Vue.extend({
    name: 'adminLocationCreate',
    data: (): Data => ({
        loading: true,
        saving: false,

        valid: true,

        id: '',
        player_id: '',
        livestreams: [],
    }),
    components: {
        NavBarComponent,
    },
    methods: {
        async submit() {
            const isValid = (this.$refs.form as any).validate()

            if (isValid) {
                this.saving = true

                console.log('submit data')

                let location: AdminLocation = {
                    id: this.id,
                    player_id: this.player_id,
                }

                await createLocation(location)

                this.saving = false

                this.$router.push({name: Routes.Locations})
            }

        },
    },
    async created() {

        const livestreams = await getLivestreams()

        this.livestreams = livestreams.map(l => {
            return {
                text: `${l.name} - ${l.id}`,
                value: l.id
            }
        })

        this.loading = false
    },
})

