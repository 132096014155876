







































































import Vue from 'vue'

import NavBarComponent from '@/admin/components/NavBarComponent.vue'

import { BASE_URL } from '@/config'

import { Routes } from '@/admin/config'
import {
  getAttendeeLogs
} from '@/admin/firebase/api'
import { AdminAttendeeLog } from '@/admin/types';

interface Data {
    eventId: string | null
    attendeeId: string | null

    attendeeLogs: AdminAttendeeLog[],

    loading: boolean

    BASE_URL: string
    ROUTES: {
        EventView: string
        EventAttendeeEdit: string
        EventAttendeeView: string
        EventAttendeeLogsView: string
    }
}

export default Vue.extend({
    name: 'adminEventAttendeeLogsView',
    components: {
        NavBarComponent,
    },
    data: (): Data => ({
        eventId: null,
        attendeeId: null,

        attendeeLogs: [],

        loading: true,

        BASE_URL,
        ROUTES: {
            EventView: Routes.EventView,
            EventAttendeeEdit: Routes.EventAttendeeEdit,
            EventAttendeeView: Routes.EventAttendeeView,
            EventAttendeeLogsView: Routes.EventAttendeeLogsView
        }
    }),
    methods: {
        async loadAttendeeLogs(eventId, attendeeId) {
            // reset
            this.loading = true
            this.eventId = null
            this.attendeeId = null
            this.attendeeLogs = []

            // load attendee
            this.eventId = eventId
            this.attendeeId = attendeeId
            console.log('loading logs')
            const newLogs = await getAttendeeLogs(eventId, attendeeId)
            this.attendeeLogs = newLogs.sort((a, b) => {

              if(a.created_at === undefined || b.created_at === undefined) return 1

              return a.created_at > b.created_at ? 1 : -1
            })
            console.log('loading logs', this.attendeeLogs)

            this.loading = false

            console.log('loading', this.loading)
        },
    },
    beforeRouteEnter(to, from, next) {
        console.log('enter')
        console.log(to.params.eventId)
        console.log(to.params.attendeeId)

        next((vm: any) => {
            vm.loadAttendeeLogs(to.params.eventId, to.params.attendeeId)
        })
    },
    beforeRouteUpdate(to, from, next) {
        console.log('update')
        console.log(to.params.eventId)
        console.log(to.params.attendeeId)

        next((vm: any) => {
            vm.loadAttendeeLogs(to.params.eventId, to.params.attendeeId)
        })
    },
})

